import React from 'react';
import {Link} from 'react-router-dom';
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai';
import logo from './../assets/logo.png';
import * as PropTypes from "prop-types";

function RouterLink(props) {
    return null;
}

RouterLink.propTypes = {
    to: PropTypes.bool,
    children: PropTypes.node
};
const Navbar = (props) => {
    const nav = props.state;

    return (
        <div className='bg-[#060504] bg-opacity-60 z-50 flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white sticky top-0'>
            <Link to={'/'}>
                <img src={logo} alt="logo" className='max-w-[250px] py-4 px-4'/>
            </ Link>
            <ul className='hidden md:flex'>
                <li className='p-4'>
                    <Link to={'/'}> Acasa </ Link>
                    </li>
                <li className='p-4'>
                    <Link to={'/produse'}> Produse </ Link>
                </li>
                <li className='p-4'>
                    <Link to={'/anunturi'}> Anunturi </ Link>
                </li>
                <li className='p-4'>
                    <Link to={'/despre-noi'}> Despre noi </ Link>
                </li>
                {/*<li className='p-4'>*/}
                {/*    <Link to={'#contact'}> Contact </ Link>*/}
                {/*</li>*/}
                <li className='p-4'>
                    <a href="https://stage.shop.graubundenmetal.ro/user/login">
                        Login
                    </a>
                </li>
            </ul>
            <div className='block md:hidden'>
                {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20}/>}
            </div>
            <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
                <Link to={'/'}>
                    <img src={logo} alt="logo" className='max-w-[150px] py-4 px-4'/>
                </ Link>
                <li className='p-4'>
                    <Link to={'/'}> Acasa </ Link>
                </li>
                <li className='p-4'>
                    <Link to={'/produse'}> Produse </ Link>
                </li>
                <li className='p-4'>
                    <Link to={'/anunturi'}> Anunturi </ Link>
                </li>
                <li className='p-4'>
                    <Link to={'/despre-noi'}> Despre noi </ Link>
                </li>
                {/*<li className='p-4'>*/}
                {/*    <Link to={'#contact'}> Contact </ Link>*/}
                {/*</li>*/}
                <li className='p-4'>
                    <a href="https://stage.shop.graubundenmetal.ro/user/login">
                        Login
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Navbar;
