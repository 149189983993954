import React from 'react';
import Blogs from "./Blogs";
import {Link} from "react-router-dom";

const Info = () => {
  return (
      <div className='bg-white flex-col justify-center items-center'>
          <Link to={'/anunturi'}>
              <h1 className='md:text-5xl sm:text-6xl text-4xl font-bold md:py-6 text-center'>
                  ANUNTURI
              </h1>
          </ Link>

          <div className="cmsmasters_column_inner" style={{maxWidth: '90vw', margin: 'auto', padding: '0 5px'}}>
              <div className="aligncenter">
                  <div className="cmsmasters_img  cmsmasters_image_c imganunt1"
                       style={{display: 'flex', justifyContent: 'center', margin: '0 0 40px 0'}}><img
                      decoding="async" src="https://graubundenmetal.ro/wp-content/uploads/2022/06/Picture2.jpg"/></div>
              </div>
              <div className="cmsmasters_text" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <p style={{textAlign: 'center', margin: '0 0 25px 0'}}>Finalizarea proiectului “<strong>Inființare și
                      dotare unitate de producție mobilier
                      metalic</strong>
                  </p>
                  <p style={{textAlign: 'start', margin: '0 0 25px 0'}}>
                      Graubunden Metal S.R.L. , în calitate de Beneficiar, anunță finalizarea proiectului „ÎNFIINȚARE ȘI
                      DOTARE UNITATE DE PRODUCȚIE MOBILIER METALIC”, cod SMIS 138025 , finanțat prin Programul
                      Operaţional Regional 2014-2020, Axa prioritară 2: “Imbunătățirea competitivității întreprinderilor
                      mici și mijlocii”, Prioritatea de investiții 2.2 – ”Sprijinirea creării și extinderea
                      capacităților avansate de producție și dezvoltarea serviciilor”.
                      Valoarea totală a proiectului este de 8.430.483,14 lei, din care valoarea sumei nerambursabile
                      este de 4.803.949,81 lei, din care valoare FEDR 4.083.357,34 lei.
                  </p>
                  <p style={{textAlign: 'start', margin: '0 0 25px 0'}}>
                      Obiectivul general al proiectului este: creșterea competitivității economice a Graubunden Metal
                      S.R.L. prin respectarea principiilor de dezvoltare durabilă.
                      Obiectivul specific al proiectului este: pătrunderea pe piața producătorilor de mobilier și
                      consolidarea poziției pe această piată.
                      Durata de implementare a proiectului este de 57 luni: respectiv de la data 01.04.2019 până la data
                      31.12.2023.
                  </p>
                  <p style={{textAlign: 'start', margin: '0 0 25px 0'}}>
                      Rezultatele proiectului:
                      1. Crearea unei noi capacități de producție a pieselor de mobilier
                      2. protecția mediului prin utilizarea surselor regenerabile de energie pentru producerea de
                      energie electrică si termică
                      Impactul proiectului la nivel local:
                      - Utilizarea forței de muncă locală;
                      - Promovarea principiilor privind egalitatea de gen, nediscriminarea, promovarea drepturilor
                      persoanelor din categorii defavorizate, utilizarea eficientă a resurselor;
                      - Dezvoltarea mediului de afaceri local și regional.

                      Proiectul susține principiile de nediscriminare, reducerea excluziunii sociale, egalitatea de
                      șanse și de gen precum și accesul la calificare și perfecționare profesională.
                  </p>
                  <p style={{textAlign: 'start', margin: '0 0 25px 0'}}>
                      Informaţii suplimentare despre proiect se pot obţine la telefon 0799.739.433, email <a
                      href="mailto:eduardursei@yahoo.com">eduardursei@yahoo.com</a> .
                  </p>
              </div>
              <div style={{width: '100vw', background: 'white', padding: '10pxs'}}>
                  <hr style={{maxWidth: '60vw', margin: 'auto', height: '2px', border: 'none', background: '#0033af'}}/>
                  <br/>
                  <hr style={{maxWidth: '30vw', margin: 'auto', height: '3px', border: 'none', background: '#0033af'}}/>
                  <br/>
                  <p style={{textAlign: 'center', color: '#0033af', fontWeight: 'bold'}}>Pentru informatii detaliate
                      despre celalte programe cofinanteate de <br/> Uniunea Europeana.</p>
                  <p style={{textAlign: 'center', color: '#0033af', fontWeight: 'bold'}}>Va invitam sa vizitati <a
                      href="https://www.fonduri-ue.ro">www.fonduri-ue.ro</a></p>
                  <br/>
                  <hr style={{maxWidth: '60vw', margin: 'auto', height: '2px', border: 'none', background: '#0033af'}}/>
                  <br/>
                  <hr style={{maxWidth: '30vw', margin: 'auto', height: '2px', border: 'none', background: '#0033af'}}/>
                  <br/>
                  <div className="cmsmasters_text textanunt2">
                      <p style={{textAlign: 'center', color: '#0033af', fontWeight: 'bold'}}>Investim în viitorul
                          tău! <br/>
                          Proiect
                          cofinanțat din Fondul European<br/>
                          de Dezvoltare Regională prin Programul Operațional Regional 2014-2020</p>
                  </div>
                  <p style={{textAlign: 'center', color: '#0033af'}}>Conținutul acestui material nu reprezintă în mod
                      obligatoriu
                      poziția
                      oficială a Uniunii Europene sau a Guvernului României.</p>
                  <br/>
                  <div className="aligncenter">
                      <div className="cmsmasters_img  cmsmasters_image_c imganunt2"
                           style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}><img
                          decoding="async" src="https://graubundenmetal.ro/wp-content/uploads/2022/06/Picture3.png"/>
                      </div>
                  </div>
                  <br/>
                  <div className="cmsmasters_text textanunt2">
                      <p style={{color: '#0033af', textAlign: 'center'}}><a style={{color: '#0033af'}}
                                                                            href="http://www.inforegio.ro">www.inforegio.ro</a> | <a
                          href="http://facebook.com/inforegio.ro">facebook.com/inforegio.ro</a></p>
                  </div>
                  <br/>
                  <br/>
              </div>
              <br/>
              <div className="cmsmasters_text textanunt2">
                  <p style={{textAlign: 'center'}}><strong>Anunțul de participare la procedura competitivă poate fi
                      descărcat de: <a
                          href="https://stage.shop.graubundenmetal.ro/public/uploads/all/JSmKtvMpqI4y9k3pAjt6AzzV9PFgnydDNmFGtJ4N.pdf"
                          target="_blank" rel="noopener">AICI</a></strong>.</p>
              </div>
          </div>

          <Blogs limit={3}/>
      </div>
  );
};

export default Info;
