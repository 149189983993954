import React, {useEffect, useState} from 'react';
import Cards from './components/Cards';
import Hero from './components/Hero';
import Blogs from './components/Blogs';
import Blog from './components/Blog';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Layout} from "./components/Layout";
import Info from "./components/InfoRegio";

function App() {
    const [nav, setNav] = useState(false);
    useEffect(() => {
        const onClickEvent = window.addEventListener('click', (e) => {
            if(e.target.tagName === 'svg') {
                setNav(!nav)
            } else if (nav && e.target.tagName !== 'svg') {
                setNav(false);
            }
        })

        return () => {
            window.removeEventListener('click',onClickEvent);
        }
    })
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" />
                <Route index element={
                    <Layout nav={nav} setNav={setNav} showInfoHeader={true} showInfoFooter={true}>
                        <Hero/>
                        <Info />
                        <Cards/>
                    </Layout>}
                />
                <Route path="/produse" element={
                    <Layout nav={nav} setNav={setNav} showInfoHeader={true} showInfoFooter={true}>
                        <Cards/>
                    </Layout>} />
                <Route path="/despre-noi" element={
                    <Layout nav={nav} setNav={setNav} showInfoHeader={true} showInfoFooter={true}>
                        <Hero/>
                    </Layout>} />
                <Route path="/anunturi" element={
                    <Layout nav={nav} setNav={setNav} showInfoHeader={true} showInfoFooter={true}>
                        <Blogs/>
                    </Layout>} />
                <Route path="/anunturi/:slug" element={
                    <Layout nav={nav} setNav={setNav} showInfoHeader={true} showInfoFooter={true}>
                        <Blog/>
                    </Layout>} />
                <Route path="*" element={
                    <Layout nav={nav} setNav={setNav}>
                        <Hero/>
                        <Info />
                        <Cards/>
                    </Layout>}
                />
                {/*<Route path="/footer" element={<Footer />} />*/}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
