import React, {useEffect} from 'react';
import {Link, useParams} from 'react-router-dom'
import api from "../api/axios";

const Blog = () => {
    const [blog, setBlog] = React.useState([]);
    const { slug } = useParams()
    const fetchBlog = () => {
        api.get(`/api/v1/blog/details/${slug}`).then((res) => {
            setBlog(res.data.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        fetchBlog();
    }, []);

    return (
        <div className='bg-gray-100 min-h-max p-10'>
            <Link to={`/anunturi`} className="bg-black text-[orange] w-[100px] p-2 rounded-lg text-center block"> Inapoi </Link>
            <div className="py-4 flex items-center justify-center">
                <img src={blog.banner} alt={blog?.meta_img ? blog.meta_img : blog.short_description} className="text-3xl font-bold" />
            </div>
            <div className="container mx-auto p-5 mt-8 rounded-lg bg-gray-200">
                <h2 className="text-2xl font-bold mb-4 text-center">{blog.title}</h2>

                <p className="text-lg text-gray-700 mb-4 text-center">{blog.short_description}</p>

                <div className="text-lg text-gray-800 mb-4" dangerouslySetInnerHTML={{__html: blog.description}}/>

                <p className="text-sm text-gray-800 text-right">Publicat in data de {new Date(blog.created_at).toDateString()}</p>
            </div>
        </div>
    );
};

export default Blog;
