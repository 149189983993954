import React from 'react';
import {Link} from "react-router-dom";

const BlogPreview = ({blog}) => {

    return (
        <div className='w-full py-[2rem] px-4 bg-gray-200 rounded-lg flex justify-center flex-col items-center'>
            <img src={blog.banner} alt=""/>
            <Link to={`/anunturi/${blog.slug}`}>
                <h4 className='text-[orange] text-center font-bold text-2xl my-4'>
                    {blog.title}
                </h4>
                <div className='max-w-[1240px] mx-auto text-center'>
                    {blog.short_description}
                </div>
                <div
                    className='flex justify-center bg-black text-[orange] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>
                    Catre anunt
                </div>
                <p className="text-xs text-gray-800 text-right">Publicat in data
                    de {blog.created_at}</p>
            </Link>
        </div>
    );
};

export default BlogPreview;
