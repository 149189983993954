import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
export const Layout = (
    {
        nav,
        setNav,
        children,
        showInfoHeader= false,
        showInfoFooter= false
    }
) => {
    return (
        <div>
            {showInfoHeader ?
                <div className="aligncenter bg-white w-[100vw]">
                    <div className="cmsmasters_img  cmsmasters_image_c imganunt1"
                         style={{display: 'flex', justifyContent: 'center'}}><img
                        decoding="async" src="https://graubundenmetal.ro/wp-content/uploads/2022/06/Picture2.jpg"/></div>
                </div>
                : '' }
            <Navbar state={nav} setNav={setNav}/>
            {children}
            <Footer/>

            {showInfoFooter ?
                <div style={{width: '100dvw', background: 'white', padding: '10px'}}>
                    <hr style={{maxWidth: '60vw', margin: 'auto', height: '2px', border: 'none', background: '#0033af'}}/>
                    <br/>
                    <hr style={{maxWidth: '30vw', margin: 'auto', height: '3px', border: 'none', background: '#0033af'}}/>
                    <br/>
                    <p style={{textAlign: 'center', color: '#0033af', fontWeight: 'bold'}}>Pentru informatii detaliate
                        despre celalte programe cofinanteate de <br/> Uniunea Europeana.</p>
                    <p style={{textAlign: 'center', color: '#0033af', fontWeight: 'bold'}}>Va invitam sa vizitati <a
                        href="https://www.fonduri-ue.ro">www.fonduri-ue.ro</a></p>
                    <br/>
                    <hr style={{maxWidth: '60vw', margin: 'auto', height: '2px', border: 'none', background: '#0033af'}}/>
                    <br/>
                    <hr style={{maxWidth: '30vw', margin: 'auto', height: '2px', border: 'none', background: '#0033af'}}/>
                    <br/>
                    <div className="cmsmasters_text textanunt2">
                        <p style={{textAlign: 'center', color: '#0033af', fontWeight: 'bold'}}>Investim în viitorul
                            tău! <br/>
                            Proiect
                            cofinanțat din Fondul European<br/>
                            de Dezvoltare Regională prin Programul Operațional Regional 2014-2020</p>
                    </div>
                    <p style={{textAlign: 'center', color: '#0033af'}}>Conținutul acestui material nu reprezintă în mod
                        obligatoriu
                        poziția
                        oficială a Uniunii Europene sau a Guvernului României.</p>
                    <br/>
                    <div className="aligncenter">
                        <div className="cmsmasters_img  cmsmasters_image_c imganunt2"
                             style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}><img
                            decoding="async" src="https://graubundenmetal.ro/wp-content/uploads/2022/06/Picture3.png"/>
                        </div>
                    </div>
                    <br/>
                    <div className="cmsmasters_text textanunt2">
                        <p style={{color: '#0033af', textAlign: 'center'}}><a style={{color: '#0033af'}}
                                                                              href="http://www.inforegio.ro">www.inforegio.ro</a> | <a
                            href="http://facebook.com/inforegio.ro">facebook.com/inforegio.ro</a></p>
                    </div>
                    <br/>
                    <br/>
                </div>
                : ''}
        </div>
    );
};
