import React, {useEffect} from 'react';
import api from "../api/axios";
import BlogPreview from "./BlogPreview";

const Blogs = ({limit}) => {
    const [blogs, setBlogs] = React.useState([]);

    const fetchBlogs = () => {
        api.get('/api/v1/all-blogs').then((res) => {
            setBlogs(res.data.blogs.data);
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        fetchBlogs();
    }, []);

    return (
        <div className='w-full py-[2rem] px-4 bg-white' id="produse">
            <h1 className='md:text-5xl sm:text-6xl text-4xl font-bold md:py-6 text-center'>
                {limit ? 'Ultimele anunturi' : 'Anunturi'}
            </h1>
            <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
                {blogs.map((blog, index) => (
                    limit? index < limit ?
                    <BlogPreview key={blog.id} blog={blog} />
                        : ''
                        : <BlogPreview key={blog.id} blog={blog} />
                ))}
            </div>
        </div>
    );
};

export default Blogs;
